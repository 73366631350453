import APIUtil from 'api/apiUtil';
import appConfig from '../../config';
const api = new APIUtil();
const api_v2 = new APIUtil(appConfig.config().baseUrl_v2);
const SignupAPI = {
  createUser(data) {
    return api.post('company/user', data, undefined, 'v4');
  },
  verifyOTP(companyId, data) {
    return api.post(`company/${companyId}/verify-email`, data, undefined, 'v4');
  },
  resendOTP(companyId, email) {
    return api.post(`company/${companyId}/send-otp`, { email }, undefined, 'v4');
  },
  companyEdit(companyId, data, config) {
    return api_v2.put(`company/${companyId}?billing=true`, data, config, 'v2');
  },
  verifyCaptcha(token) {
    return api.post(`user/verify-captcha`, { token }, undefined, 'v4');
  },
  fetchBillingAddress(companyId) {
    return api.get(`company/${companyId}/payments/billingAddress`, undefined, 'v4');
  },
  fetchBillingDetails(companyId, planId = 1, config) {
    return api.get(`company/${companyId}/billingInfo/${planId}`, config, 'v4');
  },
  subscribeCompany(companyId, data) {
    return api.post(`company/${companyId}/subscribe`, data, undefined, 'v4');
  },
  updateBillingAddress(companyId, addressId, data) {
    return api.put(
      `company/${companyId}/payments/billingAddress/${addressId}`,
      data,
      undefined,
      'v4'
    );
  },
  fetchPaymetricDetails(companyId) {
    return api.get(`company/${companyId}/subscription/iframe/accessToken`, undefined, 'v4');
  },
  fetchPlans(companyId, config) {
    return api.get(`company/${companyId}/plan`, config, 'v4');
  },
  sendLog(companyId, data) {
    return api.post(`user/${companyId}/log`, data, undefined, 'v2');
  },
  checkPlanValidityWithAddons(companyId, planId, config) {
    return api.get(`company/${companyId}/CheckPlanPrice/${planId}`, config, 'v4');
  }
};
export default SignupAPI;
