import React, { Component } from 'react';

import { Button } from 'reactstrap';

import APIUtil from 'api/apiUtil';
const api = new APIUtil();

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  async componentDidCatch(error, info) {
    try {
      const { data } = await api.post(
        'logging/frontend',
        { errorMessage: error.message, info },
        undefined,
        'v4'
      );
      console.log({ data });
    } catch (error) {
      console.log({ error });
    }
  }
  render() {
    if (this.state.hasError) {
      return (
        <div
          className='errorWrap d-flex align-items-center justify-content-center w-100'
          data-test='component-ErrorBoundary'
        >
          <span className='logoError'>
            <img className='img-fluid' src={require('../../images/errorLogo.png')} alt='' />
          </span>
          <span>
            <img className='img-fluid' src={require('../../images/Toro_Tools.png')} alt='' />
          </span>
          <div className='mb-auto text-center mt-5'>
            <h4 className='pt-3'>
              An error has occurred and we’re working to fix the problem! <br />
              We’ll be up and running shortly.
            </h4>
            <Button tag='a' href='/dashboard'>
              Go Back
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
